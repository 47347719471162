import React, { useCallback, useEffect, useState } from "react";
import './ListHouseHoldScreen.css'
import {Grid, Dropdown, Modal, Icon, Pagination,Input} from 'semantic-ui-react'
import Loader from "../../components/RouteLoader/Loader";
import axios from "axios";
import Config from "../../constants/Config";
import CardHouse from "./CardHouseList";
import {withRouter} from "react-router-dom";
import debounce from "../../function/debounce";

var CancelToken = axios.CancelToken;
var cancel;

export default withRouter(({keyword, ...props}) => {
    const getSearch = (key) => {
        return new URLSearchParams(props.location.search).get(key)
    }

    const keyword_search = [
        {key: 'all', text: 'ทั้งหมด', value: 'all'},
        {key: 'house_no', text: 'บ้านเลขที่', value: 'house_no'},
        {key: 'house_code', text: 'รหัสประจำบ้าน', value: 'house_code'},
        {key: 'survey_by', text: 'ผู้สำรวจ', value: 'survey_by'}

    ];

    const count_row = [
        {key: 10, text: '10', value: 10},
        {key: 20, text: '20', value: 20},
        {key: 50, text: '50', value: 50},
        {key: 100, text: '100', value: 100},
        {key: 150, text: '150', value: 150},
        {key: 200, text: '200', value: 200},
    ];

    const status_filter = [
        {key: 'new', text: 'ยังไม่เชื่อมข้อมูล', value: 'new'},
        {key: 'incomplete', text: 'กำลังดำเนินการ', value: 'incomplete'},
        {key: 'complete', text: 'ดำเนินการเสร็จสิ้น', value: 'complete'},
        {key: 'reject', text: 'ปฏิเสธข้อมูล', value: 'reject'},
        {key: 'approve', text: 'อนุมัติข้อมูล', value: 'approve'},
    ]

    const [open_filter, set_open_filter] = useState(false)
    const [loading_status, set_loading_status] = useState(false);
    const [reload_data, set_reload_data] = useState(false)

    const [offset, set_offset] = useState(getSearch("offset") || '10');
    const [current_page, set_current_page] = useState(getSearch("page") || '1');
    const [info, set_info] = useState([]);
    const [result, set_result] = useState([]);
    const [from, set_from] = useState(getSearch("from") || 'all');
    const [_keyword, set_keyword] = useState(getSearch("keyword") || '');
    const [area_id, set_area_id] = useState(getSearch("area_id") || '');
    const [community_id, set_community_id] = useState(getSearch("community_id") || '');
    const [village_no, set_village_no] = useState(getSearch("village_no") || '');
    const [status, set_status] = useState(getSearch("status") || '');

    const [status_modal, set_status_modal] = useState([]);
    const [status_option, set_status_option] = useState(getSearch("status") ? getSearch("status").split('.') : '');
    const [area_filter, set_area_filter] = useState([]);
    const [area_modal, set_area_modal] = useState(getSearch("area_id") || '');

    const [village_no_filter, set_village_no_filter] = useState("");
    const [community_filter, set_community_filter] = useState([]);
    const [community_modal, set_community_modal] = useState([]);
    const [community_option, set_community_option] = useState(getSearch("community_id") ? getSearch("community_id").split('.') : []);

    // Calculate First page
    const startOnPage = () => {
        return ((current_page - 1) * offset) + 1
    };

    // Calculate Last page
    const endOnPage = () => {
        return current_page * offset
    };

    // Set initial start page 1
    const defaultPage = () => {
        set_current_page('1');
    };

    // Connect API
    const connectApi = async ({
                                  offset,
                                  current_page,
                                  keyword,
                                  area_id,
                                  community_id,
                                  status,
                                  village_no,
                                  from
    }) => {
        try {
            set_result([]);
            set_loading_status(true);
            if (cancel) {
                cancel()
            }

            const query_params = new URLSearchParams({
                page:current_page,
                offset:offset,
                keyword:keyword,
                from:from,
                status:status,
                community_id:community_id,
                area_id:area_id,
                village_no:village_no
            }).toString();

            let response = await axios.get(
                `${Config.url}/api/v1/house_all?${query_params}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        'Content-Type': 'application/json',
                    },
                    cancelToken: new CancelToken(async (c) => {
                        cancel = c
                    })
                }
            ).then((response) => {
                props.history.push({
                    ...props.location,
                    search: `?${query_params}`
                });
                set_loading_status(false);
                set_reload_data(false)
                if (response.data.value.result) {
                    set_result(response.data.value.result);
                } else {
                    set_result([]);
                }
                set_info(response.data.value.info);
                return response.data
            }).catch((e) => {
                //Massege Error
            })


        } catch (e) {
            console.log('error is', e)
        }

    };

    const connectApiDebounce = useCallback(debounce(connectApi, 300),[])


    const checkFilter = () => {
        return (
            community_modal.length > 0 ||
            community_option.length > 0 ||
            community_filter.length > 0 ||
            area_modal !== "" ||
            status_option !== "" ||
            village_no_filter!== ""
        )
    }

    useEffect(() => {
        connectApiDebounce({offset,
            current_page,
            reload_data,
            keyword:_keyword,
            area_id,
            community_id,
            status,
            village_no,
            from})
    }, [
        offset,
        current_page,
        reload_data,
        _keyword,
        area_id,
        community_id,
        status,
        village_no,
        from
    ]);

    // connect Data in filter
    const callDataFilter = async () => {
        try {
            let response = await axios.get(`${Config.url}/api/v1/areas_communities`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        'Content-Type': 'application/json',
                    }
                },
            );
            set_area_filter(response.data.value);
            if (getSearch("community_id") || getSearch("area_id")) {
                console.log(getSearch("community_id"), getSearch("area_id"))
                let community = response.data.value.find((item) => {
                    return item._id === getSearch("area_id")
                })
                set_community_filter(community ? community.community : []);
            }

        } catch (e) {
            console.log('Fillter error is', e)
        }
    };

    useEffect(() => {
        callDataFilter()
    }, [])

    return (
        <div className={'listhousehold-screen'}>

            <Grid>
                <Grid.Row verticalAlign={'middle'}>

                    {/*หัวข้อ ข้อมูลครัวเรือนทั้งหมด*/}
                    <Grid.Column
                        mobile={8}
                        tablet={12}
                        computer={16}
                    >
                        <h1 className={'header-text-style'}>ข้อมูลครัวเรือนทั้งหมด</h1>
                    </Grid.Column>

                    {/*Blog Search and Filter*/}
                    <Grid.Column
                        mobile={16}
                        tablet={16}
                        computer={11}
                        floated={"right"}
                    >
                        <div style={{display: 'flex', minWidth: '345px'}}>

                            {/*Blog Search*/}
                            <div className={'search-container'}>

                                {/*Select keyword search*/}
                                <Dropdown
                                    selection
                                    // defaultValue={'all'}
                                    options={keyword_search}
                                    className={'dropdown-search'}
                                    value={from}
                                    onChange={(event, data) => {
                                        set_from(data.value);
                                        // defaultPage()
                                    }}
                                />

                                {/*Input word search*/}
                                <div className={'search-input'}>
                                    <input
                                        value={_keyword}
                                        placeholder='ค้นหา...'
                                        onChange={(e) => {
                                            set_keyword(e.target.value);
                                            defaultPage();
                                        }}
                                    />
                                    <Icon name={'search'}/>
                                </div>
                            </div>

                            {/*Filter Blog*/}
                            <Modal
                                className={'filter-modal'}
                                trigger={
                                    <button
                                        onClick={() => {
                                            set_open_filter(true)
                                        }}
                                        className={'filter-button'}>
                                        <Icon name={'filter'} size={'small'}/>
                                        <label>ตัวกรอง</label>
                                        {
                                            checkFilter() &&
                                            <div className={"filter-alert-house"}></div>
                                        }
                                    </button>
                                }
                                open={open_filter}
                            >
                                {/*Filter*/}
                                <div className={'filter-modal-screen'}>

                                    {/*Herder Filter*/}
                                    <div className={'header-filter'}>
                                        <div>
                                            <Icon name={'filter'}/>
                                            <label>ตัวกรอง</label>
                                        </div>
                                        <button onClick={() => {
                                            set_open_filter(false)
                                        }} className={'close-modal-button'}>
                                            <Icon name={'close'} size={'small'} className={'close-modal'}/>
                                        </button>
                                    </div>

                                    {/*Detail List Filter*/}
                                    <div className={'modal-content'}>

                                        {/*Input word search*/}
                                        <div className={'filter-detail'}>
                                            <div>เลขที่หมู่บ้าน</div>
                                            <div className={'search-input'}>
                                                <Input
                                                    style={{width:"100%"}}
                                                    value={village_no_filter}
                                                    placeholder='เลขที่หมู่บ้าน'
                                                    onChange={(e) => {
                                                        set_village_no_filter(e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        {/*Area of responsibility*/}
                                        <div className={'filter-detail'}>
                                            <div>ศูนย์ฯที่รับผิดชอบ</div>
                                            <Dropdown
                                                placeholder='เลือกศูนย์ฯที่รับผิดชอบ'
                                                search
                                                clearable
                                                fluid
                                                selection
                                                value={area_modal}
                                                options={
                                                    area_filter ?
                                                        area_filter.map((item) => {
                                                            return (
                                                                {
                                                                    key: item._id,
                                                                    text: item.name,
                                                                    value: item._id
                                                                }
                                                            )
                                                        })
                                                        :
                                                        []
                                                }
                                                onChange={((event, data) => {
                                                    let community = area_filter.find((item) => {
                                                        return item._id === data.value
                                                    })
                                                    set_community_filter(community ? community.community : []);
                                                    set_area_modal(data.value)
                                                })}
                                            />
                                        </div>

                                        {/*Community of responsibility*/}
                                        <div className={'filter-detail'}>
                                            <div>เขตชุมชนที่รับผิดชอบ</div>
                                            <Dropdown
                                                placeholder='เลือกเขตชุมชนที่รับผิดชอบ'
                                                search
                                                fluid
                                                clearable
                                                multiple
                                                selection
                                                disabled={!area_modal}
                                                value={community_option}
                                                options={
                                                    community_filter ?
                                                        community_filter.map((item) => {
                                                            return (
                                                                {
                                                                    key: item._id,
                                                                    text: item.name,
                                                                    value: item._id
                                                                }
                                                            )
                                                        })
                                                        :
                                                        []
                                                }
                                                onChange={((event, data) => {
                                                    if (data.value.length === 0) {
                                                        set_community_option(data.value);
                                                        set_community_modal(data.value)
                                                    } else {
                                                        let communities = data.value.reduce((accumulator, current) => {
                                                            return accumulator + '.' + current
                                                        });
                                                        set_community_option(data.value);
                                                        set_community_modal(communities)
                                                    }
                                                })}
                                            />
                                        </div>

                                        {/*Status operation*/}
                                        <div className={'filter-detail'}>
                                            <div>สถานะการดำเนินการ</div>
                                            <Dropdown
                                                placeholder='เลือกสถานะการดำเนินการ'
                                                search
                                                fluid
                                                clearable
                                                multiple
                                                selection
                                                value={status_option}
                                                options={status_filter}
                                                onChange={((event, data) => {
                                                    set_status_option(data.value);

                                                    if (data.value.length === 0) {
                                                        set_status_option(data.value);
                                                        set_status_modal(data.value)
                                                    } else {
                                                        let _status = data.value.reduce((accumulator, current) => {
                                                            return accumulator + '.' + current
                                                        });
                                                        set_status_option(data.value);
                                                        set_status_modal(_status)
                                                    }
                                                })}

                                            />
                                        </div>
                                    </div>

                                    {/*Button operation in Filter*/}
                                    <div className={'button-filter-modal'}>
                                        <a
                                            onClick={() => {
                                                set_community_modal([]);
                                                set_community_option([]);
                                                set_community_filter([]);
                                                set_area_modal('');
                                                set_status_option('')
                                                set_village_no_filter('')
                                            }}
                                        >ล้างข้อมูลทั้งหมด</a>
                                        <button
                                            onClick={() => {
                                                set_open_filter(false)
                                                defaultPage();
                                                set_area_id(area_modal)
                                                set_community_id(community_modal)
                                                set_status(status_modal)
                                                set_village_no(village_no_filter)

                                            }}
                                            className={'button-save-filter-modal'}
                                        >กรองข้อมูล
                                        </button>
                                    </div>

                                </div>

                            </Modal>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            {/*Table Household*/}
            <div className={'header-table'}>

                {/*No.*/}
                <div className={'number-content'}>
                    <label>#</label>
                </div>

                {/*Topic Table Household*/}
                <div className={'topic-content'}>
                    <div className={'topic-detail-content'}>
                        <div className={'space-content'} style={{flex: 2}}>ข้อมูลที่อยู่อาศัย</div>
                        <div className={'space-content'}>เขตพื้นที่รับผิดชอบ</div>
                        <div className={'space-content'}>ข้อมูลการสำรวจ</div>
                        <div className={'space-content'}>สถานะการดำเนินการ</div>
                    </div>
                </div>


            </div>


            {/*List all household*/}
            <div className={'blog-member-card'}>

                {
                    result.map((item, index) => {
                        return (
                            <CardHouse
                                key={index}
                                item={item}
                                index={index}
                                page={((current_page - 1) * offset) + 1 + index}/>
                        )
                    })
                }

                {
                    !!loading_status ?
                        <Loader/>
                        :
                        result.length === 0 && <div className={'none-data-container'}>ไม่มีข้อมูล</div>

                }

            </div>

            {/*Number of page*/}
            <div className={'all-page'}>
                <div className={'count-page'} style={{flex: '1'}}>
                    <label>แสดงข้อมูลลำดับที่ {startOnPage()} ถึงลำดับที่ {endOnPage() <= info.row_all ? endOnPage() : info.row_all} จากทั้งหมด {info.row_all} ข้อมูล</label>
                </div>

                <div className={'count-page'}>
                    <label>แสดง
                        <Dropdown
                            className={'input-count'}
                            defaultValue={10}
                            selection
                            options={count_row}
                            onChange={(event, data) => {
                                set_offset(data.value)
                            }}
                        />
                        แถว</label>
                    <Pagination
                        activePage={current_page}
                        ellipsisItem={{content: <Icon name='ellipsis horizontal'/>, icon: true}}
                        firstItem={{content: <Icon name='angle double left'/>, icon: true}}
                        lastItem={{content: <Icon name='angle double right'/>, icon: true}}
                        prevItem={{content: <Icon name='angle left'/>, icon: true}}
                        nextItem={{content: <Icon name='angle right'/>, icon: true}}
                        totalPages={info.end_page ? info.end_page : 1}
                        onPageChange={(event, data) => {
                            set_current_page(data.activePage);
                        }}
                    />
                </div>
            </div>

        </div>
    )
})


import React, { useEffect, useState } from "react";
import './MapScreen.css';
// import {Map, InfoWindow, Marker, GoogleApiWrapper, Polygon, IMapProps} from 'google-maps-react';
import supercluster from 'points-cluster';
import { Accordion, Icon, Form, Checkbox, Dropdown, Modal, Grid } from 'semantic-ui-react'
import axios from "axios";
import mapStyles from "../../components/Maps/mapStyles"
import Config from "../../constants/Config";
import GoogleMapReact from 'google-map-react';
import Marker from "../../components/Maps/Marker";
import ClusterMarker from "../../components/Maps/ClusterMarker";
import { markersData } from "./fake";
import GeoMarker from "../../components/Maps/GeoMarker";
import DetailMakerHouse from "./DetailMakerHouse";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import FormatContainer from "../../components/FormatContainer";
import SurveyData from "../ApproveDataScreen/SurveyData";
import StatusHouse from "./StatusHouse";

const MapScreen = (props) => {

    const [map, set_map] = useState(null)
    const [house_id, set_house_id] = useState("")
    const [view_map, set_view_map] = useState(null)

    const [area_filter, set_area_filter] = useState(true)
    const [area_house_type, set_house_type] = useState(true)

    const [area, set_area] = useState([]);
    const [area_value, set_area_value] = useState('');
    const [community, set_community] = useState([]);
    const [community_value, set_community_value] = useState('');

    const [type_map, set_type_map] = useState('SATELLITE')

    const [open_detail_house, set_open_detail_house] = useState(false)
    const [active_index, set_active_index] = useState(0)

    const [status_new, set_status_new] = useState(true)
    const [status_incomplete, set_status_incomplete] = useState(true)
    const [status_complete, set_status_complete] = useState(true)
    const [status_approve, set_status_approve] = useState(true)
    const [status_reject, set_status_reject] = useState(true)

    const [markers, set_markers] = useState([])
    const [markers_temp, set_markers_temp] = useState([])

    const [map_options, set_map_options] = useState({ center: { lat: 13.917882, lng: 100 }, zoom: 8 })
    const [clusters, set_clusters] = useState([])

    const [permission, set_permission] = useState(true)
    const [loading, set_loading] = useState(false)

    const [modal_cluster, set_modal_cluster] = useState(false)
    const [modal_cluster_list, set_modal_cluster_list] = useState([])

    const [geo, set_geo] = useState('')

    const FilterMarker = () => {
        set_loading(true)
        let _marker = markers_temp.filter((item) => {
            return (

                (!area_value || item.item.area_id === area_value) &&
                (!community_value || item.item.community_id === community_value) &&
                (
                    (item.item.status === "new" && status_new) ||
                    (item.item.status === "incomplete" && status_incomplete) ||
                    (item.item.status === "complete" && status_complete) ||
                    (item.item.status === "approve" && status_approve) ||
                    (item.item.status === "reject" && status_reject)
                )
            )
        })
        // console.log(_marker)
        set_markers(_marker)
    }

    const getAllMarker = async (latitude, longitude) => {
        set_loading(true)
        try {
            let response = await axios.get(
                `${Config.url}/api/v1/map?lat=${latitude}&lng=${longitude}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                }
            )
            let _markers = response.data.value.map((marker, index) => {
                return {
                    id: index,
                    lat: parseFloat(marker.location.latitude),
                    lng: parseFloat(marker.location.longitude),
                    item: marker
                }
            })
            set_markers_temp(_markers)
            set_markers(_markers)
            set_loading(false)
        } catch (e) {
            set_loading(false)
        }
    }

    const getClusters = () => {
        const clusters = supercluster(markers, {
            minZoom: 0,
            maxZoom: 22,
            radius: 60,
        })
        return clusters(map_options);
    };

    const createClusters = () => {
        let _clusters = map_options.bounds ? getClusters().map(({ wx, wy, numPoints, points }) => {
                return (
                    {
                        lat: wy,
                        lng: wx,
                        numPoints,
                        id: `${numPoints}_${points[0].id}`,
                        points,
                    }
                )
            })
            : []
        set_clusters(_clusters)
    };

    useEffect(() => {
        set_clusters([])
        if (map_options.bounds) {
            createClusters()
        }
        set_loading(false)
    }, [markers])

    useEffect(() => {
        set_loading(true)
        if (map) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                if (map) {
                    map.panTo({ lat: position.coords.latitude, lng: position.coords.longitude })
                    map.setZoom(16)
                }
                set_geo({ lat: position.coords.latitude, lng: position.coords.longitude })
                await getAllMarker(position.coords.latitude, position.coords.longitude)
                set_loading(false)
                set_permission(true)
            }, (error) => {
                set_permission(false)
                set_loading(false)
            });
        }
    }, [map])

    useEffect(() => {
        // console.log("map_options",map_options)
        if (map_options.bounds) {
            createClusters()
        }
    }, [map_options])

    useEffect(() => {
        connectFilterApi()
    }, [])

    useEffect(() => {
        FilterMarker()
    }, [community_value, area_value, status_new, status_incomplete, status_complete, status_approve, status_reject])

    const handleMapChange = ({ center, zoom, bounds }) => {
        set_map_options({ center, zoom, bounds })
    };

    // เชื่อมข้อมูล API ของตัวกรอง
    const connectFilterApi = async () => {
        try {
            let response = await axios.get(`${Config.url}/api/v1/areas_communities`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        'Content-Type': 'application/json',
                    }
                }
            );
            // console.log(response)
            set_area(response.data.value);
            return response.data.value

        } catch (e) {
            console.log('Fillter error is', e)
        }
    };

    return (
        <div className={'map-screen'}>

            <Modal
                onClose={() => set_modal_cluster(false)}
                open={modal_cluster}
            >
                <Modal.Header>เลือกบ้าน</Modal.Header>
                <Modal.Content image>
                    <Modal.Description>
                        <Grid>
                            <Grid.Row>

                                {
                                    modal_cluster_list.map((item, index) => {
                                        return (
                                            <Grid.Column
                                                key={`cluster-${index}`}
                                                mobile={16}
                                                tablet={4}
                                                computer={4}
                                                largeScreen={4}
                                            >
                                                <ListCusterItem
                                                    item={item.item}
                                                    onClick={()=>{
                                                        set_modal_cluster(false)
                                                        set_house_id(item.item._id)
                                                        set_open_detail_house(true)
                                                    }}
                                                />
                                            </Grid.Column>
                                        )
                                    })
                                }

                            </Grid.Row>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
            </Modal>

            {
                loading &&
                <div className={'backdrop'}>
                    <img src={require("../../assets/img/OGS-logo-white.png")}
                         style={{ width: '150px', height: '120px', marginBottom: '20px' }}/>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h1>กำลังโหลด</h1>
                        <img src={require("../../assets/loading.gif")}
                             style={{ width: '50px', height: '50px', marginBottom: '-16px' }}/>
                    </div>
                </div>
            }

            {
                !permission &&
                <div className={'backdrop'}>
                    <Icon disabled name='warning sign'/>
                    <h1>กรุณาเปิดตำแหน่งที่อยู่</h1>
                </div>
            }

            <div className={'header-content'}>
                <div className={'header-text'}>พื้นที่สำรวจ</div>
                <div className={'subheader-text'}>ข้อมูลประจำปี 2567</div>
            </div>


            {
                open_detail_house &&
                <DetailMakerHouse
                    houseId={house_id}
                    visible={open_detail_house}
                    onClose={() => {
                        set_open_detail_house(false);
                    }}
                />
            }

            <div className={'map-content'}>

                <GoogleMapReact
                    yesIWantToUseGoogleMapApiInternals
                    onChange={handleMapChange}
                    bootstrapURLKeys={{ key: Config.map_key }}
                    defaultCenter={{ lat: 13.917882, lng: 100 }}
                    onGoogleApiLoaded={({ map, maps }) => {
                        set_map(map)
                    }}
                    options={(maps) => {
                        return {
                            mapTypeId: maps.MapTypeId[type_map],
                            maxZoom: 22,
                        }
                    }}
                    defaultZoom={9}
                >
                    {clusters.map(item => {
                        if (item.numPoints === 1) {
                            return (
                                <Marker
                                    onClick={() => {
                                        set_house_id(item.points[0].item._id)
                                        set_open_detail_house(true)
                                    }}
                                    item={item.points[0].item}
                                    key={item.id}
                                    lat={item.points[0].lat}
                                    lng={item.points[0].lng}
                                />
                            );
                        }

                        return (
                            <ClusterMarker
                                onClick={() => {
                                    map.setZoom(map.getZoom() + 1)
                                    if (map.getZoom() === 19) {
                                        set_modal_cluster(true)
                                        set_modal_cluster_list(item.points)
                                    }
                                }}
                                key={item.id}
                                lat={item.lat}
                                lng={item.lng}
                                points={item.points}
                            />
                        );
                    })}

                    {
                        !!geo &&
                        <GeoMarker
                            key={'geo_location'}
                            {
                                ...geo
                            }
                        />
                    }
                </GoogleMapReact>

                <div className={'filter-content'}>

                    {/*Area Filter*/}
                    <div className={`filter-house ${area_filter && 'hide'}`}>
                        <div
                            className={'topic'}
                            onClick={() => {
                                set_area_filter(!area_filter)
                            }}
                        >
                            <Icon disabled name='map'/>
                            <h4>พื้นที่สำรวจข้อมูล</h4>
                            <Icon disabled name={`switch-icon ${area_filter ? 'caret down' : 'window minimize'}`}/>
                        </div>
                        <div>ศูนย์แพทย์</div>
                        <Dropdown
                            placeholder='เลือกศูนย์แพทย์'
                            search
                            clearable
                            fluid
                            selection
                            options={
                                area?.length > 0 ?
                                    area.map((item) => {
                                        return (
                                            {
                                                key: item._id,
                                                text: item.name,
                                                value: item._id
                                            }
                                        )
                                    })
                                    :
                                    []
                            }
                            onChange={((event, data) => {
                                let community = area.find((item) => {
                                    return item._id === data.value
                                })
                                set_area_value(data.value)
                                set_community(community ? community.community : []);
                            })}
                        />
                        <div>ชุมชน</div>
                        <Dropdown
                            placeholder='เลือกชุมชน'
                            search
                            clearable
                            disabled={community.length === 0}
                            fluid
                            selection
                            options={
                                community.length > 0 ?
                                    community.map((item) => {
                                        return (
                                            {
                                                key: item._id,
                                                text: item.name,
                                                value: item._id
                                            }
                                        )
                                    })
                                    :
                                    []
                            }
                            onChange={((event, data) => {
                                set_community_value(data.value)
                            })}
                        />
                    </div>

                    {/*House Filter*/}
                    <div className={`filter-house select-filter ${area_house_type && 'hide'}`}>
                        <div className={'topic'} style={{ padding: '0 0 25px 0' }} onClick={() => {
                            set_house_type(!area_house_type)
                        }}>
                            <Icon disabled name='filter'/>
                            <h4>ตัวกรองข้อมูล</h4>
                            <Icon disabled name={`switch-icon ${area_house_type ? 'caret down' : 'window minimize'}`}/>
                        </div>

                        <Checkbox
                            onClick={() => set_status_new(!status_new)}
                            checked={!!status_new}
                            label={
                                <label>
                                    <img className={'icon-checkcox'}
                                         src={require('../../assets/img/Maker/NoanyHouse.png')}
                                    />ยังไม่เชื่อมข้อมูล</label>
                            }/>

                        <Checkbox
                            onClick={() => set_status_incomplete(!status_incomplete)}
                            checked={!!status_incomplete}
                            label={
                                <label>
                                    <img className={'icon-checkcox'}
                                         src={require('../../assets/img/Maker/IncompleteHouse.png')}
                                    />กำลังดำเนินการ</label>
                            }/>

                        <Checkbox
                            onClick={() => set_status_complete(!status_complete)}
                            checked={!!status_complete}
                            label={
                                <label>
                                    <img className={'icon-checkcox'}
                                         src={require('../../assets/img/Maker/CompleteHouse.png')}
                                    />ดำเนินการเสร็จสิ้น</label>
                            }/>

                        <Checkbox
                            onClick={() => set_status_approve(!status_approve)}
                            checked={!!status_approve}
                            label={
                                <label>
                                    <img className={'icon-checkcox'}
                                         src={require('../../assets/img/Maker/approvehouse.png')}
                                    />อนุมัติข้อมูล</label>
                            }/>

                        <Checkbox
                            onClick={() => set_status_reject(!status_reject)}
                            checked={!!status_reject}
                            label={
                                <label>
                                    <img className={'icon-checkcox'}
                                         src={require('../../assets/img/Maker/rejecthouse.png')}
                                    />ปฏิเสธข้อมูล</label>
                            }/>

                        <Checkbox
                            checked={!!status_reject && !!status_approve && !!status_complete && !!status_incomplete && !!status_new}
                            onClick={() => {
                                if (!!status_reject && !!status_approve && !!status_complete && !!status_incomplete && !!status_new) {
                                    set_status_new(false)
                                    set_status_incomplete(false)
                                    set_status_complete(false)
                                    set_status_approve(false)
                                    set_status_reject(false)
                                } else {
                                    set_status_new(true)
                                    set_status_incomplete(true)
                                    set_status_complete(true)
                                    set_status_approve(true)
                                    set_status_reject(true)
                                }
                            }}
                            label={<label>ทั้งหมด</label>}/>

                    </div>

                    {/*Type Map*/}
                    <div className={"type-map"}>
                        <img
                            onClick={() => {
                                if (type_map === 'ROADMAP') {
                                    set_type_map('SATELLITE')
                                } else {
                                    set_type_map('ROADMAP')
                                }
                            }}
                            src={type_map === 'ROADMAP' ? require('../../assets/img/satellite.png') : require('../../assets/img/roadmap.png')}
                        />
                    </div>
                </div>

            </div>
        </div>
    )
}

const ListCusterItem = ({ item ,onClick}) => {
    let source = null
    switch (item.status) {
        case 'new':
            source = require('../../assets/img/Maker/NoanyHouse.png');
            break;
        case 'incomplete':
            source = require('../../assets/img/Maker/IncompleteHouse.png');
            break;
        case 'complete':
            source = require('../../assets/img/Maker/CompleteHouse.png');
            break;
        case 'approve':
            source = require('../../assets/img/Maker/approvehouse.png');
            break;
        case 'reject':
            source = require('../../assets/img/Maker/rejecthouse.png');
            break;
    }

    return (
        <div className={"cluster-list"} onClick={onClick}>
            <img className={'icon-marker'} src={source}/>
            <div>
                <div>บ้านเลขที่</div>
                <div className={"detail-house_no"}>{!item.data ? "ยังไม่มีบ้านเลขที่" : (item.data?.house_no || item.data?.house_code)}</div>
            </div>
        </div>
    )
}

export default MapScreen;



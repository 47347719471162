import React, { useCallback, useEffect, useState } from "react";
import './ApproveScreen.css'
import {Button, Grid, Input, Dropdown, Modal, Image, Icon, Divider, Pagination} from 'semantic-ui-react'
import axios from "axios";
import Config from "../../constants/Config";
import Loader from "../../components/RouteLoader/Loader";
import CardHouse from "./CardHouse"
import {
    withRouter
} from "react-router-dom";
import CardMember from "../../components/CardMember";
import debounce from "../../function/debounce";

var CancelToken = axios.CancelToken;
var cancel;

export default withRouter(({keyword, ...props}) => {
    const getSearch = (key) => {
        return new URLSearchParams(props.location.search).get(key)
    }

    const keywordSearch = [
        {key: 'all', text: 'ทั้งหมด', value: 'all'},
        {key: 'house_no', text: 'บ้านเลขที่', value: 'house_no'},
        {key: 'house_code', text: 'รหัสประจำบ้าน', value: 'house_code'},
        {key: 'head_member', text: 'หัวหน้าครัวเรือน', value: 'head_member'},
        {key: 'survey_by', text: 'ผู้สำรวจ', value: 'survey_by'}

    ];
    const countRow = [
        {key: 10, text: '10', value: 10},
        {key: 20, text: '20', value: 20},
        {key: 50, text: '50', value: 50},
        {key: 100, text: '100', value: 100},
        {key: 150, text: '150', value: 150},
        {key: 200, text: '200', value: 200},
    ];

    const [offset, set_offset] = useState(getSearch("offset") || '10');
    const [current_page, set_current_page] = useState(getSearch("page") || '1');
    const [from, set_from] = useState(getSearch("from") || 'all');
    const [_keyword, set_keyword] = useState(getSearch("keyword") || '');
    const [result, set_result] = useState([]);
    const [info, set_info] = useState([]);
    const [area, set_area] = useState([]);
    const [community, set_community] = useState([]);
    const [permission, set_permission] = useState([]);
    const [area_id, set_area_id] = useState(getSearch("area_id") || '');
    const [community_id, set_community_id] = useState(getSearch("community_id") || '');
    const [permission_id, set_permission_id] = useState([]);
    const [village_no, set_village_no] = useState(getSearch("village_no") || '');
    const [area_modal, set_area_modal] = useState(getSearch("area_id") || '');
    const [community_modal, set_community_modal] = useState([]);
    const [permission_modal, set_permission_modal] = useState([]);
    const [community_option, set_community_option] = useState(getSearch("community_id") ? getSearch("community_id").split('.') : []);
    const [open_filter, set_open_filter] = useState(false);
    const [open_add_member, set_open_add_member] = useState(false);
    const [loading_status, set_loading_status] = useState(false);
    const [open_detail_member, set_open_detail_member] = useState(false);
    const [id_member, set_id_member] = useState('');
    const [reload_data, set_reload_data] = useState(false)
    const [village_no_filter, set_village_no_filter] = useState("");


    // เปิด Modal
    const openModal = () => {
        set_open_filter(true)
    };

    // ปิด Modal
    const closeModal = () => {
        set_open_filter(false)
    };

    // คำนวณข้อมูลตัวแรกของหน้า
    const startOnPage = () => {
        return ((current_page - 1) * offset) + 1
    };

    // คำนวณข้อมูลตัวสุดท้ายของหน้า
    const endOnPage = () => {
        return current_page * offset
    };

    // กำหนดหน้าแรกที่แสดง
    const defaultPage = () => {
        set_current_page('1');
    };

    // เชื่อมข้อมูล API
    const connectApi = async ({
                                  current_page,
                                  offset,
                                  keyword,
                                  from,
                                  community_id,
                                  area_id,
                                  village_no
                              }) => {
        try {
            set_result([]);
            set_loading_status(true);
            if (cancel) {
                cancel()
            }


            const query_params = new URLSearchParams({
                page:current_page,
                offset:offset,
                keyword:keyword,
                from:from,
                community_id:community_id,
                area_id:area_id,
                village_no:village_no
            }).toString();

            let response = await axios.get(
                `${Config.url}/api/v1/approve_all?${query_params}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        'Content-Type': 'application/json',
                    },
                    cancelToken: new CancelToken(async (c) => {
                        cancel = c
                    })
                }
            ).then((response) => {
                props.history.push({
                    ...props.location,
                    search: `?${query_params}`
                });
                set_loading_status(false);
                set_reload_data(false)
                if (response.data.value.result) {
                    set_result(response.data.value.result);
                } else {
                    set_result([]);
                }
                set_info(response.data.value.info);
                return response.data
            }).catch((e) => {
                //Massege Error
            })


        } catch (e) {
            console.log('error is', e)
        }

    };

    const reloadData = () => {
        set_reload_data(true)
    }

    const connectApiDebounce = useCallback(debounce(connectApi, 300),[])

    useEffect(() => {
        connectApiDebounce({
            current_page,
            offset,
            keyword:_keyword,
            from,
            community_id,
            area_id,
            village_no})
    }, [
        offset,
        current_page,
        from,
        _keyword,
        permission_id,
        area_id,
        community_id,
        reload_data,
        village_no
    ]);

    const checkFilter = () => {
        return (
            community_modal.length > 0 ||
            community_option.length > 0 ||
            permission_modal.length > 0 ||
            area_modal !== "" ||
            village_no_filter!== ""
        )
    }

    useEffect(() => {
        connectFilterApi()
    }, []);
    // เชื่อมข้อมูล API ของตัวกรอง
    const connectFilterApi = async () => {
        try {
            let response = await axios.get(`${Config.url}/api/v1/filter_search`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        'Content-Type': 'application/json',
                    }
                },
            );
            // set_area(response.data.value.area);
            // set_community(response.data.value.community);
            set_permission(response.data.value.permission);
            return response.data.value

        } catch (e) {
            console.log('Fillter error is', e)
        }
    };

    useEffect(() => {
        connectFilterComApi()
    }, [])

    // เชื่อมข้อมูล API ของตัวกรอง
    const connectFilterComApi = async () => {
        try {
            let response = await axios.get(`${Config.url}/api/v1/areas_communities`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        'Content-Type': 'application/json',
                    }
                }
            );
            set_area(response.data.value);
            if(getSearch("community_id") || getSearch("area_id")){
                console.log(getSearch("community_id"),getSearch("area_id"))
                let community = response.data.value.find((item) => {
                    return item._id === getSearch("area_id")
                })
                set_community(community ? community.community : []);
            }

        } catch (e) {
            console.log('Fillter error is', e)
        }
    };

    return (
        <div className={'approve-screen'}>
            <Grid>
                <Grid.Row verticalAlign={'middle'}>

                    {/*หัวข้อ อนุมัติข้อมูลครัวเรือน*/}
                    <Grid.Column
                        mobile={8}
                        tablet={12}
                        computer={16}
                    >
                        <h1 className={'header-text'}>อนุมัติข้อมูลครัวเรือน</h1>
                    </Grid.Column>

                    {/*ช่องค้นหา*/}
                    <Grid.Column
                        mobile={16}
                        tablet={16}
                        computer={11}
                        floated={"right"}
                    >
                        <div style={{display: 'flex',minWidth:'345px'}}>
                            <div className={'search-box'}>

                                {/*เลือกคำค้นหา*/}
                                <Dropdown
                                    selection
                                    // defaultValue={'all'}
                                    options={keywordSearch}
                                    className={'dropdown-search'}
                                    value={from}
                                    onChange={(event, data) => {
                                        set_from(data.value);
                                        defaultPage()
                                    }}
                                />
                                <div className={'search-content'}>
                                    <input
                                        value={_keyword}
                                        placeholder='ค้นหา...'
                                        onChange={(e) => {
                                            set_keyword(e.target.value);
                                            defaultPage();
                                        }}

                                    />
                                    <Icon name={'search'}/>
                                </div>
                            </div>


                            {/*ปุ่มกรอง*/}
                            <Modal
                                className={'filter-modal-screen'}
                                trigger={
                                    <button onClick={openModal} className={'filter-button'}>
                                        <Icon name={'filter'} size={'small'}/>
                                        <label>ตัวกรอง</label>
                                        {
                                            checkFilter() &&
                                            <div className={"filter-alert-house"}></div>
                                        }
                                    </button>
                                }
                                open={open_filter}
                                // onClose={() => {
                                //     set_open_filter(false)
                                // }}
                            >
                                <div className={'modal-filter'}>

                                    {/*หัวข้อตัวกรอง*/}
                                    <div className={'header-filter'}>
                                        <div>
                                            <Icon name={'filter'}/>
                                            <label>ตัวกรอง</label>
                                        </div>
                                        <button onClick={closeModal} className={'close-modal-button'}>
                                            <Icon name={'close'} size={'small'} className={'close-modal'}/>
                                        </button>
                                    </div>

                                    {/*ข้อมูลที่ต้องการกรอง*/}
                                    <div className={'modal-content'}>

                                        {/*Input word search*/}
                                        <div className={'filter-detail'}>
                                            <div>เลขที่หมู่บ้าน</div>
                                            <div className={'search-input'}>
                                                <Input
                                                    style={{width:"100%"}}
                                                    value={village_no_filter}
                                                    placeholder='เลขที่หมู่บ้าน'
                                                    onChange={(e) => {
                                                        set_village_no_filter(e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        {/*ศูนย์ฯที่รับผิดชอบ*/}
                                        <div className={'filter-detail'}>
                                            <div>ศูนย์ฯที่รับผิดชอบ</div>
                                            <Dropdown
                                                placeholder='เลือกศูนย์ฯที่รับผิดชอบ'
                                                search
                                                clearable
                                                fluid
                                                selection
                                                value={area_modal}
                                                options={
                                                    area?.length > 0 ?
                                                        area.map((item) => {
                                                            return (
                                                                {
                                                                    key: item._id,
                                                                    text: item.name,
                                                                    value: item._id
                                                                }
                                                            )
                                                        })
                                                        :
                                                        []
                                                }
                                                onChange={((event, data) => {
                                                    let community = area.find((item) => {
                                                        return item._id === data.value
                                                    })
                                                    set_community(community ? community.community : []);
                                                    set_area_modal(data.value)
                                                })}
                                            />
                                        </div>

                                        {/*เขตชุมชนที่รับผิดชอบ*/}
                                        <div className={'filter-detail'}>
                                            <div>เขตชุมชนที่รับผิดชอบ</div>
                                            <Dropdown
                                                placeholder='เลือกเขตชุมชนที่รับผิดชอบ'
                                                search
                                                fluid
                                                clearable
                                                multiple
                                                selection
                                                disabled={!area_modal}
                                                value={community_option}
                                                options={
                                                    community ?
                                                        community.map((item) => {
                                                            return (
                                                                {
                                                                    key: item._id,
                                                                    text: item.name,
                                                                    value: item._id
                                                                }
                                                            )
                                                        })
                                                        :
                                                        []
                                                }
                                                onChange={((event, data) => {
                                                    if (data.value.length === 0) {
                                                        set_community_option(data.value);
                                                        set_community_modal(data.value)
                                                    } else {
                                                        let communities = data.value.reduce((accumulator, current) => {
                                                            return accumulator + '.' + current
                                                        });
                                                        set_community_option(data.value);
                                                        set_community_modal(communities)
                                                    }
                                                })}
                                            />
                                        </div>
                                    </div>

                                    {/*ปุ่มบันทึกโมดอลตัวกรอง*/}
                                    <div className={'button-filter-modal'}>
                                        <a onClick={() => {
                                            set_community_modal([]);
                                            set_community_option([]);
                                            set_permission_modal([]);
                                            set_area_modal('');
                                            set_village_no_filter('')
                                        }}>ล้างข้อมูลทั้งหมด</a>
                                        <button
                                            onClick={() => {
                                                closeModal();
                                                defaultPage();
                                                set_permission_id(permission_modal)
                                                set_area_id(area_modal)
                                                set_community_id(community_modal)
                                                set_village_no(village_no_filter)

                                            }}
                                            className={'button-save-filter-modal'}
                                        >กรองข้อมูล
                                        </button>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            {/*หัวข้อตาราง*/}
            <div className={'header-table'}>

                {/*No.*/}
                <div className={'space-block1-number'}>
                    <label>#</label>
                </div>

                {/*Topic Table Household*/}
                <div className={'row-content'}>
                    <div className={'block2-content'}>
                        <div className={'space-content'} style={{flex: 2}}>ข้อมูลที่อยู่อาศัย</div>
                        <div className={'space-content'}>เขตพื้นที่รับผิดชอบ</div>
                        <div className={'space-content'}>หัวหน้าครัวเรือน</div>
                        <div className={'space-content'}>ข้อมูลการสำรวจ</div>
                    </div>
                </div>

            </div>

            {/*แสดงข้อมูลสมาชิก*/}
            <div className={'block-member-card'}>

                {
                    result.map((item, index) => {
                        return (
                            <CardHouse
                                key={index}
                                item={item}
                                index={index}
                                page={((current_page - 1) * offset) + 1 + index}/>
                        )
                    })
                }

                {
                    !!loading_status ?
                    <Loader/>
                    :
                        result.length === 0 && <div className={'none-data-container'}>ไม่มีข้อมูล</div>
                }

            </div>


            {/*แสดงจำนวนหน้า */}
            <div className={'all-page'}>
                <div className={'count-page'} style={{flex: '1'}}>
                    <label>แสดงข้อมูลลำดับที่ {startOnPage()} ถึงลำดับที่ {endOnPage() <= info.row_all ? endOnPage() : info.row_all} จากทั้งหมด {info.row_all} ข้อมูล</label>
                </div>

                <div className={'count-page'}>
                    <label>แสดง
                        <Dropdown
                            className={'input-count'}
                            defaultValue={10}
                            selection
                            options={countRow}
                            onChange={(event, data) => {
                                set_offset(data.value)
                            }}
                        />
                        แถว</label>
                    <Pagination
                        activePage={current_page}
                        ellipsisItem={{content: <Icon name='ellipsis horizontal'/>, icon: true}}
                        firstItem={{content: <Icon name='angle double left'/>, icon: true}}
                        lastItem={{content: <Icon name='angle double right'/>, icon: true}}
                        prevItem={{content: <Icon name='angle left'/>, icon: true}}
                        nextItem={{content: <Icon name='angle right'/>, icon: true}}
                        totalPages={info.end_page ? info.end_page : 1}
                        onPageChange={(event, data) => {
                            set_current_page(data.activePage);
                        }}
                    />
                </div>

            </div>
        </div>
    )
})

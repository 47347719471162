import React from 'react';
import './App.css';
import Router from './router'
import {Provider} from 'react-redux'
import {store} from './store'

function App() {
    console.log("KKM POP Production Version 1.0.1-2 6/08/2563")
    return (
        <Provider store={store}>
            <div className="App">
                <Router/>
            </div>
        </Provider>
    );
}

export default App;

let url = ''
if (process.env.REACT_APP_ENVIRONMENT_DEPLOY === 'product') {
    url = 'https://local-survey.ogsdev.net'
} else if (process.env.REACT_APP_ENVIRONMENT_DEPLOY === 'test') {
    url = 'https://local-survey-test.ogsdev.net'
} else {
    url = 'http://127.0.0.1:1324'
    // url = 'https://local-survey.ogsdev.net'
}

// Product AIzaSyC7ad9E6SWqGdN5y7NqdGtxEZLmsf9Yeew
// Uat AIzaSyAt34cS_NeUmFPSEd17jPAwkgoJ-8Z2Yx0

export default {
    url: url,
    map_key: "AIzaSyC7ad9E6SWqGdN5y7NqdGtxEZLmsf9Yeew"
}
